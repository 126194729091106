import { useState } from "react";
import AccordionItem from "./AccordianItem";

const Accordion = (props) => {
  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0")
    }
    setClicked(index)
  };
  return (
    <ul className="accordion">
      {props.faqs 
        ? props.faqs.map((d, i) => (
          <AccordionItem
            onToggle={() => handleToggle(i)}
            active={clicked === i}
            key={`${d.question}-${i}`}
            faq={d}
            className='col-xs-6 col-md-12' />
      ))
      : 'Loading...'}
    </ul>
  );
};

export default Accordion;