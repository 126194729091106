import Accordian from "./Accordian";

export const FAQs = ({ data }) => {
  return (
    <div id='faqs' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <i className="fa fa-question"></i>
          <h2>FAQs</h2>
        </div>
        <div className='row'>
          {<Accordian faqs={data} />}
        </div>
      </div>
    </div>
  )
}