import { useRef } from "react";

const AccordionItem = ({ faq, active, onToggle }) => {
 const { question, answer } = faq;
 const contentElement = useRef();

 return (
 <div>
  <li className={`accordionItem ${active ? "active" : ""}`}>
   <button className="button" onClick={onToggle}>
     {question}
     <span className="control">{active ? "—" : "+"} </span>
    </button>
    <div
        ref={contentElement}
        className="answerWrapper"
        style={
          active
            ? { height: contentElement.current.scrollHeight }
            : { height: "0px" }
        }
      >
     <div className="answer">{answer.map(a => <p style={{ textAlign: "left", color: "inherit"}}>{a}</p>)}</div>
    </div>
   </li>
  </div>
 );
};

export default AccordionItem;