export const Services = ({ data }) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p id="services-text">
            Hormones affect every cell in our bodies. They are involved in the function of many of our vital systems, including the cardiovascular system, central nervous system and reproductive system. Deficiencies, excesses, or imbalances in hormone levels will produce symptoms that may be uncomfortable at certain times or even every day.
            <br />
            <br />
            <br />
            Hormones also help regulate bone density, cholesterol, and glucose levels and affect brain function and mood. Imbalances can impair focus and memory, as well as cause sleeplessness, mood swings, irritability, depression and anxiety.
          </p>
          <br />
          <br />
          <p>
            Here at Coastal Health and Vitality, we will assess your symptoms and correlate them with your lab values. We then tailor the treatment specific to you so you can live your best life!
          </p>
        </div>
        <div className='row'>
          {
            data?.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-12'>
                <i className={d.icon} id={d.id}></i>
                <div className='service-desc'>
                  <h3>{d.name}</h3>
                  <ul id={d.id}>
                    {d.text.map((p, i) => (
                      <li key={`${d.id}-${i}`} className="list-item-bullet">{p}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
