import { useState, useEffect } from 'react';
import { Navigation } from './components/Navigation';
import { Header } from './components/Header';
import { FAQs } from './components/FAQs';
import { About } from './components/About';
import { Services } from './components/Services';
import { Team } from './components/Team';
import { Contact } from './components/Contact';
import SmoothScroll from 'smooth-scroll';
import { Dna } from 'react-loader-spinner';

import './App.css';
import Footer from './components/Footer';

import data from './data/data.json';
const { header, contact, about, services, team, faqs } = data;

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

export default function App() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, [loading]);


    return loading ? (
        <div className="loading-container">
            <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </div>
    ) : (
        <div>
            <Navigation />
            <Header {...header} />
            <Contact {...contact} />
            <About {...about} />
            <Services data={services} />
            <Team data={team} />
            <FAQs data={faqs} />
            <Footer />
        </div>
    );
};


